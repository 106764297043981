<template>
<div class="preview-container">
    <van-row type="flex" justify="center" class="iframe-wrap">
        <iframe :src="contractViewUrl" width="100%" height="100%">
            <p>浏览器不支持</p>
        </iframe>
    </van-row>
    <van-button style="margin-top:10px;" type="info" block  :to="{name:'SignPanel'}" >我已阅读并悉知本合同条款的内容,确认签约</van-button>
</div>
</template>

<script>
export default {
    computed:{
        contractViewUrl(){
            return this.$store.state.contract.contract.templateViewUrl
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss" scoped>
.preview-container {
    height:100%;
    .iframe-wrap {
        padding: 0;
        margin: 10px;
        border: 0;
        height:80%;

        iframe {
            padding: 0;
            margin: 0;
            border: 0;
            height:100%;
        }
    }
}
</style>
